@import '../../style/themes/index';
@import '../../style/mixins/index';

@message-prefix-cls: ~'@{ant-prefix}-message';

.@{message-prefix-cls} {
  .reset-component;

  position: fixed;
  top: 16px;
  left: 0;
  z-index: @zindex-message;
  width: 100%;
  pointer-events: none;

  &-notice {
    padding: 8px;
    text-align: center;
    &:first-child {
      margin-top: -8px;
    }
  }

  &-notice-content {
    display: inline-block;
    padding: @message-notice-content-padding;
    background: @component-background;
    border-radius: @border-radius-base;
    box-shadow: @shadow-2;
    pointer-events: all;
  }

  &-success .@{iconfont-css-prefix} {
    color: @success-color;
  }

  &-error .@{iconfont-css-prefix} {
    color: @error-color;
  }

  &-warning .@{iconfont-css-prefix} {
    color: @warning-color;
  }

  &-info .@{iconfont-css-prefix},
  &-loading .@{iconfont-css-prefix} {
    color: @info-color;
  }

  .@{iconfont-css-prefix} {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: @font-size-lg;
  }

  &-notice.move-up-leave.move-up-leave-active {
    overflow: hidden;
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@primary-color: #eb3223;@success-color: #72c040;@heading-color: #757575;@icon-color: @heading-color;@disabled-color: #757575;@text-color-secondary: #8c8c8c;@component-background: var(--component-background);@font-family: 'Roboto', sans-serif;@typography-title-font-weight: 400;@heading-1-size: 32px;@font-size-base: 13px;@line-height-base: 1.25;@text-color: #757575;@border-color-base: #0c0f12;@border-color-split: @disabled-color;@border-radius-base: 0;@border-radius-sm: 0;@layout-body-background: #0c0f12;@layout-header-background: #161b20;@layout-header-height: 80px;@menu-bg: #111519;@menu-item-height: 50px;@menu-item-active-bg: #eb3223;@list-item-padding: 0 0;@tag-default-color: #0c0f12;@tag-font-size: 12px;@avatar-bg: #2b2b2b;@btn-default-border: #eb3223;@btn-default-color: @btn-default-border;@btn-disable-color: #262626;@btn-disable-bg: @disabled-color;@btn-disable-border: @disabled-color;@card-head-color: #eb3223;@slider-rail-background-color: #4a4a4a;@slider-track-background-color: #72c040;@slider-dot-border-color: #72c040;@slider-rail-background-color-hover: #909090;@slider-track-background-color-hover: #56922f;@slider-dot-border-color-active: #72c040;@slider-handle-color-focus: #72c040;@tooltip-bg: #56922f;@input-bg: #161b20;@input-color: #ffffff;@input-placeholder-color: #505050;@input-font-weight: 300;@input-disabled-bg: var(--component-background);@table-selected-row-hover-bg: transparent;@table-selected-row-bg: transparent;@table-row-hover-bg: transparent;@process-title-color: #eb3223;@skeleton-color: #262626;